.oom-form.keyword-overview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: 80vh;
}

.oom-form.keyword-overview.searched {
    height: 100%;
}

.oom-form.keyword-overview.searched .oom-field-container.action {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.oom-form.keyword-overview.searched .oom-field-container.action button.plain {
    width: auto;
    padding: 10px 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: auto;
}


.oom-form.keyword-overview.searched .oom-field-container.action button.plain:hover {
    /* background: none; */
    border: solid 2px var(--theme-button-background-color-hover);
}

.oom-form.keyword-overview .oom-form_list_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 100%; */
}

.oom-form.keyword-overview .oom-form_list_container__row {
    /* flex-direction: row; */
}

.oom-form.keyword-overview .oom-field-container .custom-inputs {
    /* min-width: 500px; */
    width: 100%;
    padding: 0px;
    border-radius: 6px;
}

.oom-form.keyword-overview .oom-custom-select-container {
    width: 100%;
    min-width: 160px;
}

.oom-form.keyword-overview .oom-field-container button {
    width: 140px;
    min-width: 140px;
}

.oom-form.keyword-overview .oom-custom-select-container,
.oom-form.keyword-overview .oom-field-container .oom-custom-select {
    /* justify-content: center; */
    padding: 0px;
}

.oom-form.keyword-overview .oom-custom-select-container .oom-select-placeholder, 
.oom-form.keyword-overview .oom-custom-select-container .selected-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
    padding: 6px 4px;
}

.oom-form.keyword-overview .oom-custom-select-container .select-item {
    color: var(--theme-global-color-5);
    padding: 6px 6px;
}

.oom-form.keyword-overview .custom-inputs input {
    color: var(--theme-global-color-6);
    padding: 10px;
    border: 1px solid #BEBEBE;
    background-color: #FBFBFB;
    border-radius: 6px;
    height: 45px;
}

.oom-form.keyword-overview .oom-custom-select-container .oom-select-dropdown {
    padding: 5px 5px;
}

.serp-analysis {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.serp-analysis__results {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.serp-analysis .oom-page-results__details_results_table {
    width: 100%;
}

.people-also-ask svg {
    fill: #B9B9B9;
    font-size: 16px;
}

.people-also-ask .oom-page-results__details_results_sub_heading {
    display: flex;
    align-items: center;
}

.oom-page-results__keyword-volume,
.oom-page-results__keyword-difficulty-intent {
    display: flex;
    flex-direction: column;
    gap: 1px;
}

.oom-page-results__details_results .search-intent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.cpc.card__item {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.cpc .card__value {
    justify-content: space-between;
}

.cpc .card__value span {
    font-size: 20px;
}

.cpc .card__value_item span.label {
    font-size: 12px;
    font-weight: normal;
}

.cpc .card__value_item span.value {
    font-size: 18px;
}

.search-volume .card__value span {
    font-size: 30px;
}

.search-volume .card__item.local-volume .card__value span {
    color: var(--theme-global-color-primary);
}

.search-volume .card__item.global-volume .card__label span,
.search-volume .card__item.global-volume .card__value span {
    color: var(--theme-global-color-white);
}

.search-volume .card__item.global-volume .card__label svg path {
    fill: var(--theme-global-color-white);
}
 
.search-volume .card__item {
    width: 100%;
    text-align: center;
    padding: 20px;
}

.search-volume .card__item.global-volume {
    background-color: var(--theme-primary-color);
}
