.oom-page-attributes.breadcrumb.ai-generation {
    background: #FBFBFB;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: sticky;
    position: -webkit-sticky;
    top: 20px;
    z-index: 1;
}

.oom-page-results__details.saved-keyword-ideas,
.oom-page-results__details.generated-primary-keywords,
.oom-page-results__details.generated-topics  {
    width: 100%;
}

.oom-form_list_container.ai-generation {
    gap: 20px;
    margin-bottom: 40px;
}

.oom-form_topics .oom-form_list_container__checkbox {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.oom-form_topics .oom-form_list_container__checkbox li label {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
}

.oom-form_topics .oom-form_list_container__checkbox .propose {
    display: flex;
    flex-direction: column;
}

.oom-form_topics .oom-form_list_container__checkbox .propose .propose-description {
    font-size: 14px;
}

.oom-form_list_container .oom-form_content {
    gap: 20px;
}

.oom-form_content .oom-form_content_heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.content-heading {
    border-top: 1px solid #DDDDDD;
    padding: 16px 0px;
}

.generated-content_container {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 40px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--theme-border-color);
}

.generated-content_topic_overview {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.generated-content_topic_overview h4 {
    font-size: 20px;
}

.generated-content_container .section-label {
    font-size: 18px;
    color: var(--theme-global-color-1);
}

.generated-content_container .topic {
    font-size: 30px;
    color: var(--theme-global-color-1);
}

.generated-content_container .references {
    padding-left: 15px;
}

.generated-content_container .references li,
.generated-content_container .references a {
    color: var(--theme-link-color);
}

.generated-content_navigation {
    padding: 20px 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 1px solid #e3e3e3;
}

.generated-content_navigation li {
    list-style: none;
}

.generated-content_navigation .nav-link {
    color: #9A9A9A;
    font-size: 12px;
    text-transform: uppercase;
}

.generated-content_buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.generated-content_buttons .oom-button {
    width: 180px;
    padding: 10px 19px;
    border-radius: 36px;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 700;
    color: #FBFBFB;
}

.generated-content_buttons .oom-button:hover {
    background: #3D58CF;
}

.generated-content_buttons .oom-button.generate-new {
    color: #3D58CF;
    background: #FFFFFF;
    border: solid 2px #3D58CF;
}

.generated-content_buttons .oom-button.generate-new:hover {
    background: #8B9BE226;
}

.generated-content_buttons .oom-button.generate-new .oom-ai-generate-icon {
    height: 16px;
}
.generated-content_meta {
    display: flex;
    flex-direction: column;
    gap: 0px;
    margin-top: 20px;
}

.generated-content_meta .meta-data {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    overflow: hidden;
}

.generated-content_meta .meta-data.title .label {
    border-top-left-radius: 4px;
}

.generated-content_meta .meta-data.title .value {
    border-top-right-radius: 4px;
}

.generated-content_meta .meta-data span {
    font-size: 14px;
}

.generated-content_meta .meta-data.title .label,
.generated-content_meta .meta-data.title .value {
    padding: 20px;
}

.generated-content_meta .meta-data.description .label,
.generated-content_meta .meta-data.description .value {
    padding: 20px;
}

.generated-content_meta .meta-data.slug .label,
.generated-content_meta .meta-data.slug .value  {
    padding: 20px;
}

.generated-content_meta .meta-data.slug .label {
    border-bottom-left-radius: 4px;
}

.generated-content_meta .meta-data.slug .value {
    border-bottom-right-radius: 4px;
}

.generated-content_meta .meta-data .label {
    width: 20%;
    color: var(--theme-global-color-1);
    background: var(--theme-global-color-4);
    padding: 5px 40px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
}

.generated-content_meta .meta-data .value {
    width: 80%;
    background: #F2F2F2;
    padding: 5px 40px;
}

.generated-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.generated-content-image {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.generated-content-image img {
    width: 100%;
}

.generated-content-image figure {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.generated-content-image figcaption {
    font-size: 12px;
    font-weight: 300;
    font-style: italic;
    text-align: center;
    color: var( --theme-global-color-1);
}

.generated-content img {
    width: 100%;
    max-width: 700px;
}

.generated-content h1 {
    font-size: 18px;
    color: var(--theme-global-color-1);
}

.generated-content h2,
.generated-content h3,
.generated-content h4 {
    font-size: 14px;
    color: var(--theme-global-color-1);
}

.generated-content p,
.generated-content strong {
    font-size: 14px;
}

.generated-content ol li {
    font-size: 14px;
    color: var(--theme-global-color-1);
}

.generated-content ul li {
    font-size: 14px;
    color: var(--theme-global-color-1);
}

.generated-content table tr th {
    font-size: 14px;
    text-align: left;
    padding: 4px 8px;
}   

.generated-content table tr td {
    font-size: 14px;
    padding: 4px 8px;
}

.generated-content .faq-item .faq-question {
    font-size: 14px;
    font-weight: bold;
}

.generated-content .faq-item .faq-answer {
    font-size: 14px;
} 

.generated-content .content-heading h4 {
    font-size: 20px;
}

/** Custom & Additional Keywords Input **/
.custom-keywords-input {
    width: 500px;
    overflow: auto;
    display: flex;
    align-items: center;
    padding: 5px 14px;
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    gap: 5px;
}

.custom-keywords-input input {
    border: none;
    outline: none;
    margin: 0px;
    flex-grow: 1;
    padding: 14px 14px;
}

.custom-keywords {
    background-color: #e0e0e0;
    border-radius: 3px;
    padding: 5px 8px;
    display: flex;
    align-items: center;
}

.custom-keywords .remove-item {
    padding-left: 5px;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
}

.custom-inputs {
    width: 100%;
    /* overflow: auto; */
    display: flex;
    align-items: center;
    padding: 0px 10px;
    color: var(--theme-global-color-1);
    border: 1px solid var(--theme-global-color-3);
    background-color: var(--theme-global-color-white);
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    gap: 5px;
}

.custom-inputs input {
    border: none;
    outline: none;
    margin: 0px;
    flex-grow: 1;
    padding: 14px 14px;
    border-radius: 10px;
}

.custom-inputs .custom-keywords {
    color: var(--theme-keyword-text-color);
    background-color: var(--theme-keyword-background-color);
    border-radius: 6px;
    padding: 6px 20px;
}

.custom-inputs .custom-keywords .remove-item {
    font-size: 14px;
}

.ai-generation .oom-field-container__row_flex{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ai-generation .oom-field-container__row.action {
    margin-top: 20px;
}

.ai-generation .oom-field-container__content {
    width: 80%;
}

.ai-generation .oom-field-container__navigation {
    width: 20%;
    gap: 20px;
    display: flex;
    flex-direction: column;
}
