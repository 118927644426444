
.oom-page-results.project-items {
    flex-wrap: wrap;
    gap: 10px;
}

.oom-page-results.project-items::after {
    content: "";             
    flex: 1 1 25%; 
}

.project-items .project-item {
    display: flex;
    justify-content: space-between;
    border: 1px solid #E8E8E8;
    border-radius: 20px;
    padding: 30px 20px;
    flex: 1 1 calc(25% - 20px);
    align-items: center;
    box-sizing: border-box;
    background-color: #FFFFFF;
    text-align: right;
}

.project-item .item-records {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.project-item .item-records span {
    font-size: 10px;
}

.project-item .item-type span {
    display: block;
    text-align: left;
}

.oom-form.project-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: 40vh;
}

.project-form .oom-field-container {
    width: 500px;
}

.project-form .oom-field-container input {
    width: 100%;
    background-color: #F6F6F6;
    border-radius: 10px;
}

.project-form .oom-field.url {
    background-color: #F6F6F6;
    border-radius: 10px;
}