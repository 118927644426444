.site-health-score, .status-overview-container {
    border: 1px solid #E0E0E0;
    text-align: center;
    border-radius: 10px;
}
.oom-page-dashboard .oom-container{
    margin-bottom: 10px;
}
.site-health-score {
    min-height: 450px;
}
.site-health-score-header{
    padding: 34px;
    border-bottom: 1px solid #E0E0E0;
}
.site-health-score-header p{
    text-transform: uppercase;
}
.site-health-score-body{
    padding: 34px;
}
.site-health-container {
    width: 200px;
    text-align: center;
    margin: 0 auto;
}

.doughnut-chart-wrapper {
    position: relative;
}

.doughnut-chart-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.percentage-value {
    margin: 0;
    font-size: 30px;
    font-weight: 400;
}

.status-text {
    font-size: 20px;
    color: #4CAF50;
    font-weight: 700;
}

.overall-score {
font-size: 14px;
margin: 10px 0;
}

.comparison-text {
font-size: 12px;
color: #757575;
}
  
.status-overview {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    width: 100%;
}

.status-item {
    text-align: left;
    padding: 10px;
    width: 30%;
}

.status-item-title {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    text-transform: uppercase;
}

.status-item-number {
    font-size: 30px;
    font-weight: 400;
    margin-right: 10px;
}

.status-item-text {
    font-size: 0.9rem;
    color: #555;
    margin-top: 5px;
}

.status-item-group{
    margin-top: 10px;
}
.status-item-line-wapper {
    display: flex;
    align-items: center;
    margin: 10px 0;
}
span.status-item-line{
    background: #555;
    height: 1px;
    width: 90%;
    display: inline-block;
    margin-left: 5%;
}
span.status-item-line.gray-line {
    background: #555;
}
.progressbar-item, .status-overview-container {
    min-height: 220px;
    display: flex;
    align-items: center;
}
.bar-chart-container{
    display: flex;
    gap: 10px;
    margin-top: 10px;
}
.progressbar-item {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.progressbar-title{
    text-transform: uppercase;
}
.progressbar-wrapper{
    width: 100%;
}
.progressbar-text{
    margin: 20px 0;
}
.summary-dot{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}
.multicolor-progressbar {
    display: flex;
    gap: 2px;
}
ul.multicolor-summary {
    list-style: none;
    text-align: left;
    margin-left: 0;
    padding-left: 0;
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 200px;
}
span.summary-title {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}
ul.multicolor-summary li {
    display: flex;
    align-items: center;
}
.issue-report-container{
    display: flex;
    gap: 10px;
}
.issue-report{
    border: 1px solid #E0E0E0;
    text-align: left;
    padding: 20px;
    width: 50%;
    border-radius: 10px;
    min-height: 450px;
}
.issue-report p{
    text-transform: uppercase;
}


@media(max-width:1024px){
    .site-health-container {
        width: 155px;
    }
    .site-health-score-header, .site-health-score-body{
        padding: 20px;
    }
    .status-overview {
        flex-direction: column;
        gap: 0;
    }
    .status-item{
        width: 100%;
        padding: 20px;
    }
    .site-health-score {
        min-height: 504px;
    }
}
@media(max-width: 768px){
    .oom-page-dashboard .oom-container, .issue-report-container{
        flex-direction: column;
    }
    .oom-page-dashboard .oom-column-30, .oom-page-dashboard .oom-column-70, .issue-report{
        width: 100%!important;
    }
    .site-health-score {
        min-height: auto;
    }
    .site-health-container {
        width: 170px!important;
    }
    .issue-report{
        min-height: 200px;
    }
}

@media(max-width: 630px){
    .bar-chart-container{
        flex-direction: column;
    }
    .bar-chart-container .progressbar-item {
        width: 100% !important;
    }
}