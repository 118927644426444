* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  margin: 0;
  color: #2c3e50;
  text-decoration: none;
  font-family: "Nunito Sans", sans-serif;
}

html {
  background: var(--theme-background-color);
  scroll-behavior: smooth;
}

p {
  color: var(--theme-global-color-1);
}

span {
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}

span.primary-color-1 {
  font-size: 14px;
  color: var(--theme-global-color-1);
}

.external-link {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.link-underline {
  text-decoration: underline;
}

.is-hidden {
  opacity: 0;
  visibility: hidden;
}

.hide {
  display: none !important;
}

.not-visible {
  display: none !important;
}

.domain-prefix input,
.domain-prefix select {
  border: 1px solid var(--theme-border-color);
  background-color: transparent;
}

.p-text {
  color: var(--theme-global-color-primary);
}

.primary-text {
  color: var(--theme-global-color-primary);
}

.secondary-text {
  color: var(--theme-global-color-5);
}

.domain-prefix select.prefix {
  cursor: pointer;
  padding: 12px 12px;
  border-radius: 0px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.domain-prefix input.url {
  padding: 13px 12px;
  border-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tags {
  font-size: 12px;
}

.url {
  font-size: 12px;
  cursor: pointer;
}

.text {
  font-size: 12px;
  text-transform: none;
}

.text-normal {
  font-size: 12px;
  font-weight: normal;
  color: var(--theme-text-normal);
} 

.highlighted {
  font-size: 12px;
  font-weight: normal;
  color: var(--theme-primary-color);
}

.selected-text {
  background-color: yellow;
  color: black;
}

/* .list-item {
  list-style: none;
  display: inline-block;
} */

.resizable textarea {
  resize: both;
  border-radius: 8px; 
}

.circular-progressbar text {
  transform: translate(-10px, 10px);
}

.circular-progressbar.seo text {
  transform: translate(-25px, 10px);
}

.badge {
  font-size: 12px;
  padding: 2px 4px;
  border-radius: 4px;
  margin-right: 4px;
}

.badge.badge-primary {
  color: var(--theme-global-color-white);
  background-color: var(--theme-global-color-secondary);
}

/** Container & Column **/
.oom-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.oom-column-5 {
  width: 5%;
}

.oom-column-10 {
  width: 10%;
}

.oom-column-15 {
  width: 15%;
}

.oom-column-20 {
  width: 20%;
}

.oom-column-15 {
  width: 15%;
}

.oom-column-30 {
  width: 30%;
}
.oom-column-35 {
  width: 35%;
}

.oom-column-40 {
  width: 40%;
}

.oom-column-50 {
  width: 50%;
}

.oom-column-60 {
  width: 60%;
}

.oom-column-70 {
  width: 70%;
}

.oom-column-80 {
  width: 80%;
}

.oom-column-90 {
  width: 90%;
}

.oom-column-100 {
  width: 100%;
}

/** Mui Dialog **/
.MuiDialog-root.oom-dialog .MuiModal-backdrop {
  background-color: #0c1229bf;
}

.MuiDialog-root.oom-dialog .MuiPaper-elevation {
  padding: 80px;
  border-radius: 20px;
}

.MuiDialog-root.oom-dialog .MuiOutlinedInput-root {
  border-color: var(--theme-global-color-2);
  border-radius: 6px;
}

.MuiDialog-root.oom-dialog .MuiDialogTitle-root {
  font-weight: bold;
  color: var(--theme-text-color);
}

.MuiDialog-root.oom-dialog .MuiDialogActions-spacing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  gap: 10px;
}

.MuiDialog-root.MuiModal-root .edit-prompt {
  width: 400px;
}

.MuiDialog-root.MuiModal-root .edit-action .ai-suggest.button {
  padding: 2px 10px;
}

.MuiDialogContent-root.oom-dialog-content {
  padding: 10px 20px;
}

.MuiDialogContent-root.oom-dialog-content h2 {
  padding: 10px 0px;
}

/** Mui Papeer **/
.MuiPaper-root {
  overflow-x: auto;
}

.MuiPaper-root.oom-menu-list .MuiMenu-list  {
  max-width: 100%!important;
}

.MuiPaper-root .MuiMenu-list {
  max-height: 250px;
  /* max-width: 400px; */
  max-width: auto;
}

/** Mui Input **/
.MuiInputLabel-outlined {
  background: #ffffff;
  padding: 2px 8px!important;
  border-radius: 6px;
}

/** Mui Calendar **/
.MuiPickersDay-root.Mui-selected {
  background-color: var(--theme-primary-color)!important;
}

/** Search Intent **/
.search-intent .card__value span.search-intent.transactional,
.search-intent .intent-container .transactional {
  color: #377658;
  background-color: #afefc3;
}

.search-intent .card__value span.search-intent.commercial,
.search-intent .intent-container .commercial {
  color: #8f631d;
  background-color: #f0dc87;
}

.search-intent .card__value span.search-intent.informational,
.search-intent .intent-container .informational {
  color: #3868b9;
  background-color: #c7e2fd;
}

.search-intent .card__value span.search-intent.navigational,
.search-intent .intent-container .navigational {
  color: #512a94;
  background-color: #c5b9da;
}

.search-intent .intent-container .na {
  color: #6c6e7c;
  background-color: #dcdde5;
}

.search-intent .card__value {
  gap: 10px;
}

.search-intent .card__value .tooltip-icon {
  display: flex;
  gap: 10px;
}

.search-intent .intent-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.search-intent .intent-container span,
.difficulty .difficulty-container span {
  font-size: 12px;
}

.search-intent .intent-container .intent {
  padding: 4px;
  border-radius: 4px;
}

/** Icon SVG **/
button.icon-svg.gemini-arrow {
  fill: #1a73e8;
  background-color: transparent;
  border: none;
}

button.icon-svg.awesome {
  background: rgb(140, 106, 193);
  background: linear-gradient(
    90deg,
    rgba(140, 106, 193, 1) 0%,
    rgba(81, 136, 211, 1) 35%,
    rgba(59, 150, 220, 1) 100%
  );
  border: none;
  border-radius: 4px;
  padding: 6px 8px;
}

span.icon-svg.plain {
  border: none;
  border-radius: 100%;
  padding: 4px 6px;
}

button.icon-svg.plain {
  background: transparent;
  border: none;
}

button.icon-svg.rectangle {
  border-radius: 100%;
  padding: 4px 10px;
}

span.icon-svg.circle.sm {
  border-radius: 100%;
  padding: 3px 6px;
}

span.icon-svg.circle,
button.icon-svg.circle {
  border-radius: 100%;
  padding: 4px 6px;
}

span.icon-svg.warning {
  cursor: pointer;
  border: solid 1px #e8c100;
}

span.icon-svg.error {
  cursor: pointer;
  border: solid 1px #e80000;
}

span.icon-svg.error path {
  fill: #e80000;
}

span.icon-svg.warning path {
  fill: #e8c100;
}

span.icon-svg path {
  fill: #000000;
}

span.icon-svg,
button.icon-svg {
  cursor: pointer;
  color: #000000;
  border: solid 1px #000000;
}

svg.icon-check {
  margin-right: 8px;
}

svg.icon-check path {
  fill: #aadfdb;
}

/** Global Menu **/
.oom-global-menu-section {
  position: fixed;
  /* top: 15px; */
  /* left: 15px; */
  height: calc(100vh);
  width: 85px;
  transition: 0.5s;
}

.oom-global-menu-section.expand {
  width: 400px;
  z-index: 1;
}

.oom-ai-generate-icon {
  height: 24px;
}

.oom-expand-collapse-icon {
  position: absolute;
  top: 15px;
  right: -12px;
  width: 24px;
  height: 24px;
  z-index: 3;
}

.oom-global-menu-section.expand .oom-expand-collapse-icon {
  transform: rotate(180deg);
}

.oom-collapse-menu {
  position: relative;
  width: 100px;
  height: 100%;
  padding: 40px 10px;
  background: linear-gradient(
    180deg,
    var(--theme-background-gradient-color-1) 0%,
    var(--theme-background-gradient-color-2) 100%
  );
  /* background: linear-gradient(180deg, #3D59D0 0%, #2D80FE 100%); */
  /* border-radius: 10px; */
  display: flex;
  flex-direction: column;
  gap: 40px;
  z-index: 1;
  overflow: auto;
}

.expand .oom-collapse-menu {
  /* border-radius: 10px 0px 0px 10px; */
}

.oom-collapse-menu::-webkit-scrollbar {
  width: 6px;
}

.oom-collapse-menu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

.oom-collapse-menu::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 10px;
}

.oom-collapse-menu_company {
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
}

.oom-collapse-menu_options {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: auto;
}

.oom-collapse-menu_option {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  opacity: 0.4;
}

.oom-collapse-menu_option:hover {
  opacity: 1;
}

.oom-collapse-menu_option-icon {
  width: 24px;
  height: 24px;
}

.oom-collapse-menu_option-title {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  color: var(--theme-global-color-2);
  text-align: center;
  display: block;
  text-transform: uppercase;
}

.oom-collapse-menu_links {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.oom-collapse-menu_link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

button.oom-collapse-menu_link {
  cursor: pointer;
  background-color: transparent;
  border: none;
}

button.oom-collapse-menu_link svg {
  fill: #ffffff;
}

.oom-collapse-menu_link-icon {
  width: 20px;
}

.oom-collapse-menu_link.profiles .oom-collapse-menu_link-icon {
  width: 40px;
}

.oom-expanded-menu {
  width: 310px;
  height: 100%;
  background-color: var(--theme-global-color-2);
  border-radius: 10px;
  padding: 48px 10px;
  position: absolute;
  right: 0px;
  top: 0;
  z-index: 0;
  opacity: 0;
  transition: 0.5s;
}

.oom-global-menu-section.expand .oom-expanded-menu {
  opacity: 1;
}

.oom-expanded-menu_option_accordion-content {
  display: none; /* Initially hidden */
}

.oom-expanded-menu_option_accordion-content.active {
  display: block; /* Show when active */
}

.oom-expanded-menu_option_accordion-links {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.oom-expanded-menu_option_accordion-link {
  padding: 10px 20px;
  text-transform: uppercase;
  font-size: 12px;
  color: var(--theme-primary-color);
  opacity: 0.6;
  transition: 0.5s;
}

.oom-expanded-menu_option_accordion-link:hover {
  opacity: 1;
}

/** Section Content **/
.oom-main-content-section {
  height: calc(100vh - 40px);
  width: calc(100% - 5%);
  background-color: var(--theme-background-color);
  position: fixed;
  left: 100px;
  top: 0px;
  /* border-radius: 8px; */
  overflow: auto;
  scroll-behavior: smooth;
  scroll-padding-top: 140px;
}

.oom-main-content-section.collapse {
  padding: 15px 15px 15px 430px;
}

.oom-main-content-section.expanded::after {
  content: "";
  background: #e1e9f180;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}

/** Notice **/
.oom-notice {
  background: #fff2f2;
  padding: 25px 60px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 15px;
  border-radius: 10px;
}

.oom-notice_icon,
.oom-notice_close {
  width: 24px;
  height: 24px;
}

.oom-notice_close {
  cursor: pointer;
}

.oom-notice_text {
  font-size: 14px;
  font-weight: 400;
  margin-right: auto;
}

/** Page Header **/
.oom-page-header {
  padding: 20px 28px;
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px #342c2c0d;
  background-color: var(--theme-global-color-2);
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 50;
}

/** Page Content **/
.oom-page-content {
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: auto;
  max-width: 1000px;
  min-height: calc(100vh - 30px);
}

.oom-page-content .ai-loader,
.oom-dialog-content .ai-loader {
  text-align: center;
  display: block;
}

.oom-page-attributes.breadcrumb {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 15px;
  /* border-bottom: solid 1px #DDDDDD; */
}

.oom-page-attributes_breadcrumb {
  font-size: 12px;
  font-weight: 400;
  color: #b9b9b9;
}

.oom-page-attributes_breadcrumb .highlight {
  color: var(--theme-text-color);
}

.oom-page-attributes_title {
  font-size: 20px;
  font-weight: 700;
}

.oom-page-attributes_title .highlight {
  color: var(--theme-primary-color);
}

.oom-page-attributes__arrow span {
  font-size: 12px;
  cursor: pointer;
  display: flex;
}

.oom-page-attributes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  padding-bottom: 15px;
  /* border-bottom: solid 1px #DDDDDD; */
}

.oom-page-attributes_info {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.oom-page-attributes_last-update {
  font-size: 14px;
  font-weight: 400;
}

.oom-page-sub-attributes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
}

.oom-page-sub-attributes .keywords {
  font-size: 12px;
}

.oom-page-sub-attributes .keywords ul {
  padding: 0px;
  list-style: none;
  display: flex;
  gap: 10px;
}

.oom-page-sub-attributes .keywords ul li,
.oom-page-sub-attributes .website {
  padding: 10px 20px;
  background-color: #e7efff;
  border-radius: 8px;
}

.oom-page-sub-attributes.keywords-provides {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-around;
  justify-content: flex-start;
  gap: 10px;
}

/** Page Results **/
.oom-page-results {
  display: flex;
  flex-direction: row;
  gap: 40px;
}

.oom-page-results__navigation {
  width: 15%;
  min-width: 180px;
  display: flex;
  gap: 10px;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #e6e6e6;
  padding: 20px;
  border-radius: 10px;
}

.oom-page-results__navigation_items {
  height: 100vh;
}

.oom-page-results__navigation .oom-page-results__navigation_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
  cursor: pointer;
}

.oom-page-results__navigation .oom-page-results__navigation_item .keyword {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: center;
}

.oom-page-results__navigation .oom-page-results__navigation_item .keyword svg {
  margin-left: -5px;
}

.oom-page-results__details_results_sub_heading {
  padding: 10px 0px;
}

.oom-page-results__details_results_sub_heading span {
  font-size: 12px;
  font-weight: bold;
  color: #222222;
}

.oom-page-results__details {
  width: 85%;
  display: flex;
  flex-direction: column;
}

.oom-page-results__details_heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
}

.oom-page-results__details_heading .oom-page-results__details_heading_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.oom-page-results__details_heading
  .oom-page-results__details_heading_datefilter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.oom-page-results__details_footer {
  display: flex;
  flex-direction: row;
}

.oom-page-results__details_results {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  background: #f7faff;
  border-bottom: solid 1px #ececec;
  border-radius: 10px;
}

.oom-page-results__details_results {
  width: 100%;
  overflow-x: auto; /* Enables horizontal scrolling */
}

.oom-page-results__details_results_table {
  min-width: 1000px; /* Ensures table doesn't shrink */
  border-collapse: collapse;
  table-layout: fixed;
}

.oom-page-results__details_results_heading {
  padding: 10px 0px;
}

.oom-page-results__details_results_heading span {
  font-size: 20px;
  font-weight: 700;
}

.oom-page-results__details_results_table {
  padding: 0px;
}

.oom-page-results__details_results_table_heading,
.oom-page-results__details_results_list_heading {
  text-align: left;
}

.oom-page-results__details_results_list_heading {
  display: flex;
  flex-direction: row;
}

.oom-page-results__details_results_table_heading_item,
.oom-page-results__details_results_list_heading_item {
  padding: 10px 10px;
  border-top: 1px solid #ececec;
  border-right: 1px solid #ececec;
}

.oom-page-results__details_results_table_heading_item:last-child {
  border-right: none;
}

.oom-page-results__details_results_table_heading_item span,
.oom-page-results__details_results_list_heading_item span {
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.oom-page-results__details_results_list_heading_item.item-value span {
  font-weight: normal;
}

.oom-page-results__details_results_list_heading_item.oom-column-50 {
  width: 50%;
}

.oom-page-results__details_results_list_heading_item.oom-column-30 {
  width: 30%;
}

.oom-page-results__details_results_list_heading_item.oom-column-20 {
  width: 20%;
}

.oom-page-results__details_results_list_heading_item.oom-column-25 {
  width: 25%;
}

.oom-page-results__details_results_list_heading_item .actions,
.oom-page-results__details_results_table_item .actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.oom-page-results__details_results_table_heading_item.sortable {
  cursor: pointer;
}

.oom-page-results__details_results_table_heading_item svg {
  fill: #a1a1a1;
}

.oom-page-results__details_results_table_item {
  padding: 10px;
}

.oom-page-results__details_results_table_item .column-item {
  padding: 10px;
  border-top: 1px solid #ffffff;
  border-bottom: 0px solid #ffffff;
  border-right: 1px solid #ececec;
}

.oom-page-results__details_results_table_item.sub-row .column-item {
  padding-left: 15px;
}

.oom-page-results__details_results_table_item .column-item .keyword-item {
  display: flex;
  flex-direction: row;
  align-content: center;
  gap: 10px;
}

.oom-page-results__details_results_table_item
  .column-item
  .keyword-item
  .source {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.oom-page-results__details_results_table_item
  .column-item
  .keyword-item
  .source
  .keyword-input
  input {
  margin-top: 0px;
}

.oom-page-results__details_results_table_item .column-item .title,
.oom-page-results__details_results_table_item .column-item .question,
.oom-page-results__details_results_table_item .column-item .answer,
.oom-page-results__details_results_table_item .column-item .url {
  font-size: 14px;
}

.oom-page-results__details_results_table_item .column-item .url-link {
  cursor: pointer;
}

.oom-page-results__details_results_table_item .column-item .url-link svg path {
  color: var(--theme-primary-color);
}

.oom-page-results__details_results_table_item .column-item .url-link svg,
.oom-page-results__details_results_table_item .column-item .url-no-link svg {
  width: 100%;
}

.oom-page-results__details_results_table_item .column-item .url-no-link {
  cursor: unset;
}

.oom-page-results__details_results_table_item
  .column-item
  .url-no-link
  svg
  path {
  color: #333333;
}

.oom-page-results__details_results_table_item .column-item .search-volume,
.oom-page-results__details_results_table_item .column-item .search-volume {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  gap: 10px;
}

.oom-page-results__details_results_table_item .column-item canvas {
  cursor: pointer;
  width: 100px !important;
  height: 50px !important;
}

.oom-page-results__details_results_table_item .column-item .title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.oom-page-results__details_results_table_item .column-item .url {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 5px;
}

.oom-page-results__details_results_table_item .column-item .url span {
  word-break: break-all;
}

.oom-page-results__details_results_table_item .column-item .title svg,
.oom-page-results__details_results_table_item .column-item .url svg {
  fill: #b9b9b9;
  font-size: 14px;
}

.oom-page-results__details_results_table_item .column-item .serp-features {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.oom-page-results__details_results_table_item .column-item:last-child {
  border-right: none;
}

.oom-page-results__details_results_table_item .column-item .actions {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.oom-page-results__details_results_table_item .column-item .created-at,
.oom-page-results__details_results_table_item .column-item .updated-at {
  font-size: 12px;
}

.oom-page-results__details_results_table_item .checkbox {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}

/** SERP Features **/
.serp-features-icon svg {
  fill: #b9b9b9;
}

/** Page Filter **/
.oom-page-filter {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.dates-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.dates-filter .dates-range {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dates-filter-range {
  display: flex;
  gap: 10px;
}

.dates-range-from,
.dates-range-to {
  display: flex;
  flex-direction: column;
}

.oom-datepicker-field .css-jupps9-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 2px 20px;
  border-radius: 20px;
}

.oom-datepicker-field .css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 0px;
}

/** Sticky **/
.sticky-navigation {
  height: 80%;
  overflow: auto;
  position: sticky;
  top: 15%;
}

/** Global Form **/
.oom-form_box {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.oom-form_title {
  color: var(--theme-text-color);
  font-size: 24px;
  font-weight: 700;
}

.oom-form_subtitle {
  color: var(--theme-text-color);
  font-size: 12px;
  font-style: italic;
}

.oom-form_sub_heading {
  color: #323232;
  font-size: 14px;
  font-weight: 700;
}

.oom-form,
.oom-results {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 768px;
}

.oom-form.coming-soon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 80vh;
}

.oom-form_list_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.oom-form_list_container_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.oom-form_list_container__row {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.oom-form_list_container .oom-form_list_container__checkbox {
  margin-top: 10px;
  padding: 0px;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.oom-form_list_container__checkbox input {
  margin-right: 10px;
}

.oom-form.default-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 80vh;
}

.default-form .oom-field-container {
  width: 500px;
}

.default-form .oom-field-container input {
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 10px;
}

.default-form .oom-field.url {
  background-color: #f6f6f6;
  border-radius: 10px;
}

.container__heading {
  color: #323232;
  font-size: 14px;
  font-weight: 700;
}

.container__sub_heading {
  color: #323232;
  font-size: 12px;
  font-weight: 400;
}

.field__heading {
  font-size: 14px;
  font-weight: normal;
  color: var(--theme-global-color-1);
}

/* Buttons */
.oom-button {
  cursor: pointer;
  padding: 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: space-around;
}

.oom-button:disabled {
  opacity: 0.6;
}

.oom-button.plain {
  font-size: 14px;
  font-weight: 700;
  color: var(--theme-button-color);
  background-color: var(--theme-button-background-color);
  border: solid 2px var(--theme-button-background-color);
  border-radius: 4px;
  gap: 10px;
}

.oom-button.plain svg path {
  color: var(--theme-button-color);
}

.oom-button.plain.no-bg {
  color: var(--theme-text-color);
  background-color: transparent;
}

.oom-button.plain.no-bg svg path {
  color: var(--theme-text-color);
}

.oom-button.plain.no-bg:hover {
  color: var(--theme-text-color-hover);
  background-color: transparent;
}

.oom-button.plain.no-bg:hover svg path {
  color: var(--theme-text-color-hover);
}

.oom-button.active {
  color: var(--theme-button-color-active);
  background-color: var(--theme-button-background-color-active);
  border-color: var(--theme-button-border-color-active);
}

.oom-button.active svg {
  fill: var(--theme-button-color-active);
}

.oom-button .img-icon {
  height: 20px;
}

.oom-form button,
.oom-form input,
.oom-form select {
  /* border-radius: 20px; */
}
.oom-form button,
.oom-form input[type="submit"] {
  background-color: var(--theme-button-background-color);
  width: fit-content;
  color: #ffffff;
  padding: 14px 100px;
  cursor: pointer;
}

.oom-form button:hover,
.oom-form input[type="submit"]:hover {
  background-color: var(--theme-button-background-color-hover);
}

.toggle-expand {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toggle-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.toggle-button .button-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  color: var(--theme-primary-color);
}

.toggle-button.white svg path {
  fill: var(--theme-global-color-white);
}

.action-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  background: #ffffff;
  border: none;
  cursor: pointer;
  transition: visibility 0.2s, opacity 0.2s ease-in-out;
  border: 0.1rem solid var(--theme-border-color);
  border-radius: 4px;
  padding: 0px;
  line-height: 0px;
}

.action-button:hover {
  background-color: var(--theme-global-color-5);
}

.editing-mode {
  background-color: var(--theme-global-color-white);
  border: none;
  border-bottom: 0.1rem solid var(--theme-border-color);
  padding: 4px 4px;
}

/* Show on hover */
.actions .more-button  {
  min-width: auto;
}

.actions:hover .more-button {
  visibility: visible;
  opacity: 1;
}

.actions.visible .more-button {
  visibility: visible;
  opacity: 1;
}

/* Initially hide the more button */
.more-button {
  min-width: auto;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s ease-in-out;
  cursor: pointer;
  background: transparent;
  border: 0.15rem solid #d6d8e3;
  border-radius: 4px;
  padding: 0px;
  line-height: 0px;
}

/* Keep visible when hover and clicked */
.more-button:hover,
.more-button.active {
  min-width: auto;
  background: #d6d8e3;
  visibility: visible;
  opacity: 1;
}

.more-button-container .MuiPopover-paper {
  padding: 0px;
  border-radius: 2px;
  margin-top: 5px;
  bottom: 62% !important;
  left: 45% !important;
}

.more-button-container .MuiMenu-list {
  padding: 0px;
}

.MuiInputBase-root.custom-select {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--theme-primary-color);
  background-color: var(--theme-global-color-white);
  cursor: pointer;
  border-radius: 6px;
  gap: 10px;
  padding: 0px 10px;
}

.MuiInputBase-root.custom-select .MuiSelect-select {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
}

.MuiInputBase-root.custom-select fieldset {
  border: solid 2px var(--theme-primary-color);
}

.oom-button.add-remove-btn {
  background: #e9f2ff;
  border: none;
  outline: none;
  padding: 5px;
  width: 90px;
  border-radius: 30px;
  font-size: 22px;
  line-height: 15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.oom-button.add-remove-btn svg {
  fill: #231f20;
}

.oom-button.add-remove-btn:hover svg {
  fill: #ffffff;
}

.oom-button.action {
  margin: 0px;
  padding: 12px 24px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 700;
  text-transform: none;
  color: var(--theme-global-color-2);
  background-color: var(--theme-button-background-color);
}

.oom-button.action:hover {
  background-color: var(--theme-button-background-color-hover);
}

.oom-button.action:disabled {
  cursor: not-allowed;
  background-color: var(--theme-button-background-color-disable);
  opacity: 1;
}

.oom-button.action.icon {
  min-width: auto;
  /* width: 100%; */
  border-radius: 4px;
  padding: 10px;
}

.oom-button.action.icon svg {
  fill: #ffffff;
}

.oom-button.action.no-bg {
  color: var(--theme-primary-color);
  border: 1px solid var(--theme-button-border-color);
  background-color: transparent;
}

.oom-button.action.no-bg svg {
  fill: var(--theme-primary-color);
}

.oom-button.action.no-bg:hover {
  color: var(--theme-global-color-white);
  border: 1px solid var(--theme-global-color-primary);
  background-color: var(--theme-global-color-primary);
}

.oom-button.action.no-bg:hover svg {
  fill: var(--theme-global-color-white);
}

button.oom-tab-item {
  padding: 16px 24px;
  border-radius: 4px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  text-transform: uppercase;
  color: var(--theme-tab-color);
  background-color: var(--theme-tab-background-color);
}

/** Tool Tip **/
.tooltip-icon {
  cursor: pointer;
}

.tooltip-container {
  cursor: pointer;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
}

svg.tooltip-icon {
  font-size: 18px;
  cursor: pointer;
}

svg.tooltip-icon path {
  fill: var(--theme-primary-color);
}

.MuiTooltip-tooltipPlacementTop {
  background-color: var(--theme-primary-color) !important;
  max-width: fit-content !important;
}

.MuiTooltip-popper[data-popper-placement*="top"]
  .css-3v9qdb-MuiTooltip-tooltip {
  margin-bottom: 0px !important;
}

.MuiTooltip-popper[data-popper-placement*="top"] {
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  background-color: var(--theme-primary-color);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 4px 1px;
  border-radius: 10px;
}

.MuiTooltip-popper[data-popper-placement*="top"] strong {
  color: #ffffff;
}

.MuiTooltip-popper[data-popper-placement*="top"] p {
  color: #ffffff;
}

/** SERP **/
.serp-features-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

/** Tabs **/
.oom-tabs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 0px;
}

.oom-tabs .oom-tab-item {
  cursor: pointer;
  padding: 10px 20px;
  text-align: center;
  color: var(--theme-tab-color);
  border-radius: 0px;
  border: solid 1px var(--theme-tab-border-color);
  background-color: var(--theme-tab-background-color);
}

.oom-tabs .oom-tab-item.tab-left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.oom-tabs .oom-tab-item.tab-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.oom-tabs .oom-tab-item.active {
  color: var(--theme-tab-color-active);
  background-color: var(--theme-tab-background-color-active);
  border-color: var(--theme-tab-border-color-active);
}

.oom-tabs .oom-tab-item:hover {
  color: var(--theme-tab-color-hover);
  background-color: var(--theme-tab-background-color-hover);
  border-color: var(--theme-tab-border-color-hover);
}

/** Default Fields **/
.oom-field-container .oom-field {
  border: 1px solid var(--theme-border-color);
  border-radius: 30px;
  padding: 12px 30px;
  width: 100%;
}

.oom-field-container .custom-inputs {
  width: 100%;
  overflow: auto;
  display: flex;
  align-items: center;
  padding: 4px 10px;
  border: 1px solid #e8e8e8;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  gap: 5px;
}

.oom-field.oom-field-select.material .MuiInputBase-input {
  font-size: 14px;
  /* color: #E8E8E8; */
  padding: 5px 20px;
}

.oom-field-container .oom-field.material {
  padding: 0px 0px;
}

.oom-field-container__row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.oom-field-container__row.action {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.oom-field-container__row.action.center {
  justify-content: center;
}

.oom-field-container__row.flex-direction-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.oom-field-container__row.flex-direction-row.flex-start {
  align-items: flex-start;
}

.oom-field-container__row.flex-direction-row.justify {
  justify-content: space-between;
}

.oom-field-container__row.align-items-center {
  align-items: center;
}

.oom-field-container__row_heading {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.oom-field-container__row.two-columns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.oom-field-container__row.two-columns .oom-field-container__row {
  width: 100%;
}

.oom-custom-select-container
  .css-ovbmre-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  border-radius: 10px;
}

.oom-custom-select-container .MuiSelect-select {
  font-size: 14px;
  color: #231f20;
  padding: 10px 30px;
}

.oom-field.oom-field-select.material {
  width: 300px;
  border-radius: 80px;
  border: 1px solid #e8e8e8;
}

.oom-field .MuiOutlinedInput-root {
  font-size: 14px;
  width: 100%;
  border-radius: 8px;
  padding: 10px 20px;
  border: none;
  background-color: var(--theme-global-color-2);
}

.oom-field .MuiOutlinedInput-root svg {
  color: var(--theme-global-color-1);
}

.oom-field .MuiOutlinedInput-root fieldset {
  border: 1px solid var(--theme-table-border-color);
  border-radius: 8px;
}

.profile-form .oom-field .MuiOutlinedInput-root {
  padding: 0px;
}

.oom-field .MuiInputBase-input {
  padding: 0px;
}

.oom-field.oom-text-field .MuiOutlinedInput-root {
  border-radius: 8px;
}

.oom-field.oom-text-field.custom-width {
  width: 600px;
}

.oom-field.oom-text-field label {
  font-size: 12px;
  padding: 0px 10px;
  background: #ffffff;
  border-radius: 20px;
}

/* Table Checkbox Fields */
.keyword-input {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.keyword-input .keyword {
  display: flex;
  flex-direction: row;
}

.keyword-input .keyword-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.keyword-input .keyword-container .keyword,
.result-text span {
  text-transform: capitalize;
  font-size: 12px;
  font-weight: 400;
  color: #222222;
}

.keyword-input .keyword-container .description {
  font-size: 12px;
  font-weight: 300;
  color: #222222;
}

.oom-page-results__navigation_item.active .keyword {
  color: #0366d8;
  text-decoration: underline;
}

.oom-page-results__navigation_item.active .total {
  color: #0366d8;
}

.oom-form_coming_soon_wapper {
  margin: 0 auto;
  max-width: 415px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.oom-form_coming_soon_title {
  color: #6f6f6f;
  font-size: 40px;
  font-weight: 700;
}
.oom-form_coming_soon_desc {
  color: #6f6f6f;
  font-size: 15px;
}
.oom-form_coming_soon_wapper svg {
  max-width: 130px;
}


