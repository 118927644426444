.oom-form.audit-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: 80vh;
}

.audit-form .oom-field-container {
    width: 500px;
}

.audit-form .oom-field-container input {
    width: 100%;
    background-color: #F6F6F6;
    border-radius: 10px;
}

.audit-form .oom-field.url {
    background-color: #F6F6F6;
    border-radius: 10px;
}