.oom-table-listing {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    overflow: hidden;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.oom-table-listing-scrollable {
    overflow: auto;
    max-height: 800px;
}

.oom-table-listing__heading {
    min-width: 100%;
    width: fit-content;
    display: flex;
    flex-direction: row;
    background-color: var(--theme-global-color-6);
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 0;
}

.oom-table-listing__heading.sticky {
    overflow: auto;
    position: sticky;
    top: 0%;
    z-index: 3;
}

.oom-table-listing__heading .oom-table-listing__heading_item {
    padding: 14px;
    font-size: 12px;
    font-weight: 700;
    position: relative;
}

.oom-table-listing__heading .oom-table-listing__heading_item span {
    color: #FFFFFF;
}

.oom-table-listing__items {
    border: solid 1px var(--theme-table-border-color);
}

.oom-table-listing__item {
    color: var(--theme-text-color-hover);
    min-width: 100%;
    width: fit-content;
    display: flex;
    flex-direction: row;
    background-color: var(--theme-global-color-white);
    padding: 0;
}

.oom-table-listing__item .item {
    padding: 4px 14px;
}

.oom-table-listing__item .item span {
    font-size: 14px;
    font-weight: normal;
    color: var(--theme-color);
}

.oom-table-listing__item .item span.date {
    font-size: 10px;
}

/* Border */
.table-border-r {
    border-right: solid 1px var(--theme-table-border-color);
}

/* Width */
.table-width-100 {
    width: 100%;
}

.table-width-90 {
    width: 90%;
}

.table-width-80 {
    width: 80%;
}

.table-width-70 {
    width: 70%;
}

.table-width-65 {
    width: 65%;
}

.table-width-60 {
    width: 60%;
}

.table-width-50 {
    width: 50%;
}

.table-width-40 {
    width: 40%;
}

.table-width-30 {
    width: 30%;
}

.table-width-25 {
    width: 25%;
}

.table-width-20 {
    width: 20%;
}

.table-width-15 {
    width: 15%;
}

.table-width-10 {
    width: 10%;
}

.table-width-5 {
    width: 5%;
}

.table-max-width-100px {
    max-width: 100px;
}