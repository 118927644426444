


@media (max-width: 1920px){
    .oom-page-content{
        max-width: 1400px;
        position: relative;
        right: 40px;
    }
}

@media (max-width: 1440){
    .oom-page-content{
        max-width: 1280px;
        position: relative;
    }
}


@media (max-width: 1280){
    .oom-page-content{
        max-width: 1000px;
        position: relative;
    }
}


@media (max-width: 1024px){
    .oom-page-content{
        max-width: 800px;
        padding: 10px 0px;
    }
}