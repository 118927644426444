.oom-page-sub-attributes.website {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: space-around;
    justify-content: flex-start;
    gap: 10px;
}

.oom-field-container.oom-competitor-urls__heading span {
    font-size: 12px;
}

.oom-field-container.oom-competitor-urls__container {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.oom-field-container.oom-competitor-urls__container  .oom-competitor-url-item {
    display: flex;
    flex-direction: row;
    gap: 15px;
}

.keyword-ideas-analysis .oom-tabs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 20px;
    padding: 10px;
    box-shadow: 0px 0px 10px 0px #0000001A;
    border-radius: 8px;
    width: 100%;
}

.keyword-ideas-analysis .oom-tab-item.active,
.keyword-ideas-analysis .oom-tab-item:hover {
    color: #FFFFFF;
    background: #2C81FF;
}

.keyword-ideas-analysis .oom-tab-item {
    border: none;
    color: #333333;
    background: #FFFFFF;
    padding: 8px 20px;
    border-radius: 8px;
    width: 170px;
    cursor: pointer;
}


.oom-page-results.keyword-ideas-analysis {
    flex-direction: column;
    gap: 10px;
}

.oom-page-results .oom-page-results__details {
    width: 100%;
}


.competitor-comparison .oom-field-container {
    width: 500px;
}

.oom-form.competitor-comparison {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: 80vh;
}

.oom-form.competitor-comparison.searched {
    height: 100%;
}


.oom-form.competitor-comparison .oom-custom-select-container .oom-custom-select {
    padding: 0px;
}

.oom-form.competitor-comparison .oom-custom-select-container .oom-select-placeholder {
    justify-content: space-between;
    align-items: center;
    padding: 4px;
}

.oom-form.competitor-comparison .oom-custom-select-container .selected-item {
    padding: 6px;
    gap: 10px;
}

.oom-form.competitor-comparison .oom-field.url {
    background-color: #F6F6F6;
    border-radius: 10px;
}


.oom-form.competitor-comparison .oom-custom-select-container .oom-select-placeholder {
    justify-content: space-between;
    align-items: center;
    padding: 4px;
}

.oom-form.competitor-comparison .oom-custom-select-container .select-item {
    color: #B3B3B3;
    padding: 6px 6px;
}

.competitor-comparison .oom-field-container.action {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
