.project-ranking .oom-table-listing__heading.sticky {
    z-index: 4;
}

.keyword-ranking .position {
    min-width: 6.8rem !important;
    width: 6.8rem !important;
}

.keyword-ranking .fixed-column-keyword {
    max-width: 80rem;
    min-width: 67rem;
    /* max-width: 40rem !important; */
    /* min-width: 20rem !important; */
    width: 40% !important;
    left: 0;
    position: -webkit-sticky!important;
    position: sticky!important;
    z-index: 2;
}

.keyword-ranking .fixed-column-url {
    max-width: 7rem !important;
    min-width: 7rem !important;
    width: 7rem !important;
    position: -webkit-sticky!important;
    position: sticky!important;
    left: 320px;
    text-align: center;
}

.keyword-ranking .oom-table-listing__heading_item {
    background-color: var(--theme-global-color-secondary);
}

.keyword-ranking .oom-table-listing__heading {
    /* min-width: auto; */
}

.keyword-ranking .oom-table-listing__heading_item.fixed-column-keyword,
.keyword-ranking .oom-table-listing__heading_item.fixed-column-url {
    background-color: var(--theme-global-color-secondary);
    z-index: 3;
}

.keyword-ranking .item.fixed-column-keyword,
.keyword-ranking .item.fixed-column-url {
    background-color: var(--theme-global-color-white);
}

.keyword-ranking .position-item .position-indicator.up svg {
    fill: #136f48;
}

.keyword-ranking  .position-item .position-indicator.down svg {
    fill: #da1e28;
}

.keyword-ranking  .position-item .position-difference {
    font-size: 12px;
}

.keyword-ranking  .position-item .position-difference.down {
    color: #da1e28;
}

.keyword-ranking  .position-item .position-difference.up {
    color: #136f48;
}

.tooltip-container.url-changes-history {
    display: flex;
    flex-direction: column;
}

.tooltip-container.url-changes-history h4 {
    margin-bottom: 10px;
}

.tooltip-container.url-changes-history h4,
.tooltip-container.url-changes-history ul li span {
    color: #FFFFFF;
}

.tooltip-container.url-changes-history ul li span.date {
    font-size: 12px;
    font-weight: bold;
    color: #DDDDDD;
}

.tooltip-container.url-changes-history ul li span.url {
    font-size: 12px;
    text-decoration: underline;
}

.tooltip-container.url-changes-history ul {
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tooltip-container.url-changes-history ul li {
    list-style: none;
    display: flex;
    flex-direction: column;
}

