:root {
    --theme-color: #0c1229;
    --theme-background-color: #f5f5f5;
    --theme-text-color: #2c3e50;
    --theme-text-color-hover: #5d6d7e;
    --theme-text-normal: #9A9A9A;
  
    --theme-link-color: #5d6d7e;
    --theme-button-link-text-color: #5d6d7e;
    --theme-button-link-background-color: #DFE2E5;
  
    --theme-keyword-text-color: #4e4e4e;
    --theme-keyword-background-color: #e1e9f1;
  
    --theme-border-color: #bebebe;
    --theme-table-border-color: #bebebe;
  
    --theme-primary-color: #2c3e50;
    --theme-active-color: #85b4b8;
    --theme-hover-color: #5d6d7e;
    --theme-disable-color: #aeaeae;
    --theme-loading-color: #5d6d7e;
  
    --theme-global-color-primary: #2c3e50;
    --theme-global-color-secondary: #338288;
    --theme-global-color-white: #ffffff;
    --theme-global-color-1: #2b2b2b;
    --theme-global-color-2: #fbfbfb;
    --theme-global-color-3: #e8e8e8;
    --theme-global-color-4: #e2e2e2;
    --theme-global-color-5: #bebebe;
    --theme-global-color-6: #333333;
    --theme-global-color-7: #85B4B8;
  
    --theme-notice-background-success: #e7f7e8;
    --theme-notice-background-warning: #fff4e3;
    --theme-notice-background-danger: #fde3e3;
  
    --theme-notice-color-success: #26762c;
    --theme-notice-color-warning: #c47e16;
    --theme-notice-color-danger: #a40c0a;
  
    --theme-notice-border-success: #8cda93;
    --theme-notice-border-warning: #f9c576;
    --theme-notice-border-danger: #f78483;
  
    --theme-background-gradient-color-1: #2c3e50;
    --theme-background-gradient-color-2: #2c3e50;
  
    --theme-button-background-color: #2c3e50;
    --theme-button-color: #ffffff;
    --theme-button-border-color: #2c3e50;
  
    --theme-button-background-color-active: #2c3e50;
    --theme-button-color-active: #ffffff;
    --theme-button-border-color-active: #2c3e50;
  
    --theme-button-background-color-hover: #5d6d7e;
    --theme-button-color-hover: #ffffff;
    --theme-button-border-color-hover: #5d6d7e;
  
    --theme-button-background-color-disable: #aeaeae;
    --theme-button-color-disable: #ffffff;
    --theme-button-border-color-disable: #aeaeae;
  
    --theme-tab-background-color: #ffffff;
    --theme-tab-color: #2c3e50;
    --theme-tab-border-color: #25357c;
  
    --theme-tab-background-color-active: #2c3e50;
    --theme-tab-color-active: #ffffff;
    --theme-tab-border-color-active: #2c3e50;
  
    --theme-tab-background-color-hover: #5d6d7e;
    --theme-tab-color-hover: #ffffff;
    --theme-tab-border-color-hover: #5d6d7e;
  
    --login-register-container-background-color: #ffffff;
    --login-register-button-background-color: #2c3e50;
    --login-register-button-text-color: #ffffff;
  }
  