.oom-form.keyword-suggestion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    height: 80vh;
}

.oom-form.keyword-suggestion.searched {
    height: 100%;
}

.oom-field-container.use-type {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
}

.oom-field-container.use-type input {
    margin-right: 10px;
}

.keyword-suggestion .oom-field-container.action {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.oom-form.keyword-suggestion .oom-field-container button {
    width: 120px;
    min-width: 140px;
}

.keyword-suggestion.searched .custom-inputs input {
    min-width: 240px;
}

.oom-form.keyword-suggestion .oom-custom-select-container .selected-item {
    padding: 6px;
    gap: 10px;
}

.oom-form.keyword-suggestion .oom-field-container .custom-inputs {
    background-color: #F6F6F6;
    padding: 4px;
    border-radius: 6px;
}

.oom-form.keyword-suggestion .custom-inputs input {
    padding: 10px;
    border-radius: 6px;
    background-color: var(--theme-global-color-2);
}

.oom-form.keyword-suggestion .oom-field.url {
    border-radius: 6px;
    background-color: var(--theme-global-color-2);
}

.oom-form.keyword-suggestion .oom-custom-select-container .oom-select-placeholder {
    justify-content: space-between;
    align-items: center;
    padding: 4px;
}

.oom-form.keyword-suggestion .oom-custom-select-container .select-item {
    color: #B3B3B3;
    padding: 6px 6px;
}

.oom-form.keyword-suggestion .oom-custom-select-container .oom-custom-select {
    padding: 0px;
}

.oom-table-listing__item .item span.competition-low,
.oom-table-listing__item .item span.competition-medium,
.oom-table-listing__item .item span.competition-high,
.oom-table-listing__item .item span.competition-unspecified {
  font-weight: bold;
}

.oom-table-listing__item .item span.competition-low {
  color: #36a93f;
}

.oom-table-listing__item .item span.competition-medium {
  color: #f59e1b;
}

.oom-table-listing__item .item span.competition-high {
  color: #e4100e;
}