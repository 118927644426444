.oom-button-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.oom-top-filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.oom-top-filter_item.select-item,
.select-item,
.input-item {
  width: 100%;
  background-color: #ffffff;
  padding: 0px;
  border-radius: 10px;
}

.oom-top-filter_item.select-item .MuiSelect-select {
  padding: 14px 10px;
}

.oom-top-filter_item.label-item {
  width: auto;
  min-width: 200px;
}

.oom-top-filter_item.label-item span {
  font-size: 14px;
  color: var(--theme-color);
}

.oom-top-header p {
  color: var(--theme-global-color-1);
}

.oom-top-header h4 {
  font-size: 24px;
}

.keyword-manager-list .oom-top-header h4 {
  font-size: 16px;
}

.oom-top-header__heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
}

.oom-top-header__heading .MuiInputBase-input {
  padding: 7px 14px;
  background-color: var(--theme-global-color-white);
  border-radius: 6px;
}

.oom-top-header__heading .MuiInputBase-adornedStart {
  background-color: var(--theme-global-color-white);
}

.oom-top-header__heading .MuiInputAdornment-positionStart {
  margin-right: 0px;
}

.oom-top-header__heading .MuiInputAdornment-positionStart svg path {
  fill: var(--theme-disable-color);
}

/* General Statistic */
.oom-general-statistic {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.oom-general-statistic .search-volume {
  border: solid 1px #bebebe;
  border-radius: 8px;
  box-shadow: 0px 0px 40px 0px #0000000a;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
}

.oom-general-statistic .search-volume .graph {
  padding: 20px 20px 40px 20px;
}

.oom-general-statistic .search-volume .volume {
  /* padding: 20px 20px 40px 20px; */
}


.oom-general-statistic .widgets {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.oom-general-statistic .widgets .widget {
    padding: 14px 20px;
    /* height: 180px; */
    border-radius: 8px;
    /* background: linear-gradient(
      180deg,
      rgba(231, 247, 232, 0.5) 6.98%,
      rgba(140, 218, 147, 0.5) 100%
    ); */
    box-shadow: 0px 0px 40px 0px #0000000a;
    border: solid 1px #bebebe;
    position: relative;
}

/* Toastify */
.Toastify__toast-body {
  font-size: 14px;
}

/* General Style */
.center,
.text-center {
  text-align: center;
}

.white-bg {
  background-color: #ffffff;
}

.border-line {
  border: 1px solid var(--theme-border-color);
}

.sortable,
.clickable,
.url-link {
  cursor: pointer;
}

.sortable svg path {
  fill: var(--theme-global-color-white);
}

.clickable.active {
  text-decoration: underline;
  font-weight: bold;
}

.clickable.active span {
  color: var(--theme-active-color);
}

.full-width {
  width: 100%;
}

.full-width .keywords {
  width: 100%;
}

.button-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 6px 10px;
  color: var(--theme-button-link-text-color);
  background-color: var(--theme-button-link-background-color);
  border-radius: 4px;
  text-transform: lowercase;
}

.button-link span {
  font-size: 12px;
  color: var(--theme-keyword-text-color);
}

.text-link {
  color: var(--theme-link-color);
}

.date_filter .MuiInputBase-input {
  padding: 10px 14px;
}

/* Padding Style */
.pd-right-40 {
  padding-right: 40px;
}

.pd-right-30 {
  padding-right: 30px;
}

.pd-right-20 {
  padding-right: 20px;
}

.pd-right-10 {
  padding-right: 10px;
}

.pd-left-40 {
  padding-left: 40px;
}

.pd-left-30 {
  padding-left: 30px;
}

.pd-left-20 {
  padding-left: 20px;
}

.pd-left-10 {
  padding-left: 10px;
}

.pd-top-40 {
  padding-top: 40px;
}

.pd-top-30 {
  padding-top: 30px;
}

.pd-top-20 {
  padding-top: 20px;
}


.pd-top-12 {
  padding-top: 12px;
}

.pd-top-10 {
  padding-top: 10px;
}

.pd-bottom-40 {
  padding-bottom: 40px;
}

.pd-bottom-30 {
  padding-bottom: 30px;
}

.pd-bottom-20 {
  padding-bottom: 20px;
}

.pd-bottom-12 {
  padding-bottom: 12px;
}

.pd-bottom-10 {
  padding-bottom: 10px;
}

/* Margin Style */
.mg-top-40 {
  margin-top: 40px;
}

.mg-top-20 {
  margin-top: 20px;
}

.mg-left-10 {
  margin-left: 20px;
}

/* Flex Direction */
.flex-direction-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-direction-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-around {
  justify-content: space-around;
}

.align-items-normal {
  align-items: normal;
}

.align-items-center {
  align-items: center;
}

.align-items-flex-start {
  align-items: flex-start;
}

.gap {
  display: flex;
}

.gap-100 {
  gap: 100px;
}

.gap-90 {
  gap: 90px;
}

.gap-80 {
  gap: 80px;
}

.gap-70 {
  gap: 70px;
}

.gap-60 {
  gap: 60px;
}

.gap-50 {
  gap: 50px;
}

.gap-40 {
  gap: 40px;
}

.gap-30 {
  gap: 30px;
}

.gap-30 {
  gap: 30px;
}

.gap-20 {
  gap: 20px;
}

.gap-10 {
  gap: 10px;
}

.gap-0 {
  gap: 0px;
}

/* Steps */
.oom-steps-container .oom-steps {
  width: 25%;
  text-align: center;
  float: left;
  cursor: pointer;
}

.oom-steps-container .oom-steps.active .oom-step-bar {
  background-color: var(--theme-global-color-secondary);
}

.oom-steps-container .oom-steps.done .oom-step-bar {
  scale: 1.5;
  background-color: var(--theme-global-color-secondary);
}

.oom-steps-container .oom-steps .oom-step-bar svg {
  display: flex;
  position: relative;
  top: -10px;
  font-size: 20px;
  fill: var(--theme-global-color-white);
}

.oom-steps-container .oom-steps .oom-step-bar {
  border-radius: 50% !important;
  background-color: var(--theme-global-color-5);
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-top: 0px;
  text-align: center;
  color: #fff;
  padding-top: 10px;
}

.oom-steps-container .oom-steps.step-one .oom-progress-bar {
  margin-left: 50%;
}

.oom-steps-container .oom-steps.step-four .oom-progress-bar {
  width: 50%;
  margin-right: 50%;
}

.oom-steps-container .oom-steps .oom-progress-bar {
  height: 2px;
  background-color: var(--theme-global-color-5);
  margin: -14px 0 0 50%;
  width: 100%;
  margin-left: 0%;
  float: left;
}

.oom-steps-container .oom-progress-title {
  padding-top: 20px;
}

.oom-steps-container .oom-steps.active .oom-progress-title span {
  color: var(--theme-primary-color);
}

.oom-steps-container .oom-progress-title span {
  font-size: 14px;
  color: var(--theme-global-color-5);
}

/** No Found Results **/
.no-found {
  padding: 40px;
}
.no-found p {
  text-align: center;
}

.no-found p a {
  padding: 0px 3px;
}

/* Notices */
.oom-notices {
  padding: 10px;
  text-align: center;
  border-radius: 4px;
}

.oom-notices.success {
  color: var(--theme-notice-color-success);
  background-color: var(--theme-notice-background-success);
  border: 1px solid var(--theme-notice-border-success);
}

.oom-notices.warning {
  color: var(--theme-notice-color-warning);
  background-color: var(--theme-notice-background-warning);
  border: 1px solid var(--theme-notice-border-warning);
}

.oom-notices.danger {
  color: var(--theme-notice-color-danger);
  background-color: var(--theme-notice-background-danger);
  border: 1px solid var(--theme-notice-border-danger);
}



/** Card **/
.card__item {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.card__label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.card__label span {
  font-size: 18px;
  font-weight: bold;
  color: var(--theme-global-color-1);
}

.card__label span svg {
  fill: var(--theme-primary-color);
}

.card__value {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.card__value span {
  font-size: 46px;
  font-weight: bold;
}

.card__value span.search-intent {
  font-size: 16px;
  font-weight: bold;
  border-radius: 6px;
  color: #6c6e7c;
  background-color: #dcdde5;
  padding: 15px 20px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

/** Width % **/
.width-100 {
  width: 100%;
}

.width-95 {
  width: 95%;
}

.width-90 {
  width: 90%;
}

.width-85 {
  width: 85%;
}

.width-80 {
  width: 80%;
}

.width-75 {
  width: 75%;
}

.width-70 {
  width: 70%;
}

.width-65 {
  width: 65%;
}

.width-60 {
  width: 60%;
}

.width-55 {
  width: 55%;
}

.width-50 {
  width: 50%;
}

.width-45 {
  width: 45%;
}

.width-40 {
  width: 40%;
}

.width-35 {
  width: 35%;
}

.width-30 {
  width: 30%;
}

.width-25 {
  width: 25%;
}

.width-20 {
  width: 20%;
}

.width-15 {
  width: 15%;
}

.width-10 {
  width: 10%;
}

/* Pagination */
.oom-pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: nowrap;
  align-content: center;
}

.oom-pagination button.pagination-item {
  padding: 10px;
  width: auto;
}

.oom-pagination button.pagination-item.right-arrow {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.oom-pagination button.pagination-item.left-arrow {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.oom-pagination .pagination-item {
  cursor: pointer;
  border: 1px solid var(--theme-table-border-color);
  background-color: var(--theme-global-color-white);
  padding: 10px 15px;
}

.oom-pagination .pagination-item.active {
  background-color: var(--theme-global-color-primary);
  border: none;
}

.oom-pagination .pagination-item.active span {
  color: var(--theme-global-color-white);
}

.oom-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.oom-footer__per_page {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.oom-footer__per_page .MuiSelect-select {
  padding: 10px 20px;
  background-color: var(--theme-global-color-white);
}
