
.oom-grid-listing__items {
    display: grid;
}

.grid-three-columns {
    grid-template-columns: repeat(3, 1fr);
}

.oom-grid-listing__item .header {
    background-color: var(--theme-global-color-6);
    padding: 14px 10px;
    border-radius: 4px;
    border: 1px solid var(--theme-border-color);
}

.oom-grid-listing__item .header a{
    color: var(--theme-global-color-white);
    text-decoration: none;
}

.oom-grid-listing__item .header svg path {
    fill: var(--theme-global-color-white);
}

.oom-grid-listing__item .body {
    background-color: var(--theme-global-color-white);
    padding: 20px;
    border-left: 1px solid var(--theme-border-color);
    border-right: 1px solid var(--theme-border-color);
}

.oom-grid-listing__item .footer {
    background-color: var(--theme-global-color-white);
    padding: 20px;
    border-radius: 4px;
    border: 1px solid var(--theme-border-color);
}