.oom-login-register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 100vh;
}

.oom-login-register h2 {
    font-size: 24px;
}

.oom-login-register button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--login-register-button-text-color);
    background-color: var(--login-register-button-background-color);
    border-radius: 6px;
    border: none;
    padding: 10px 20px;
    width: 100%;
}

.oom-login-register button[disabled] {
    background-color: gray;
}

.oom-login-register__container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.oom-login-register__container .forget-password {
    font-size: 12px;
    color: var(--theme-color);
    cursor: pointer;
    text-align: center;
}

.oom-login-register__logo {
    display: flex;
    flex-direction: column;
}

.oom-login-register__logo span {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    color: var(--theme-color);
}

.oom-login-register__form {
    background-color: var(--login-register-container-background-color);
    padding: 40px 60px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 500px;
}

.oom-login-register__row.action {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.oom-login-register__row.password {
    position: relative;
}

.oom-login-register__row.password svg {
    fill: #B3B3B3;
}

.oom-login-register__row.remember-me label {
    color: #B3B3B3;
    cursor: pointer;
    font-size: 12px;
    display: flex;
    align-items: center;
    align-content: center;
    gap: 5px;
}

.oom-login-register__row.divider {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.oom-login-register__row.divider span {
    color: #B3B3B3;
    font-size: 14px;
    font-family: light;
}

.oom-login-register__row.register-login {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.oom-login-register__row.register-login span,
.oom-login-register__row.register-login a {
    font-size: 14px;
}

.oom-login-register__row.register-login a {
    text-decoration: underline;
}

.oom-login-register__row.policy-links {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.oom-login-register__row.policy-links p {
    font-size: 12px;
}

.oom-login-register__row.policy-links a {
    text-decoration: underline;
}

.oom-login-register__row.version {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.oom-login-register__row .oom-field {
    border: solid 1px #E8E8E8;
    border-radius: 4px;
    padding: 12px 30px;
    width: 100%;
}

.oom-login-register__row .small-text {
    font-size: 10px;
}

.password-visibility-icon {
    position: absolute;
    top: 50%;
    right: 10px;  /* Adjust as needed */
    transform: translateY(-50%);
    cursor: pointer;
    color: #777;  /* Change the color as needed */
    z-index: 10;  /* Ensure the icon is on top of the input */
}

.password-visibility-icon svg {
    width: 24px;  /* Adjust the size of the icon */
    height: 24px; /* Adjust the size of the icon */
}